
//== Font loading

@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: '#{$name}';
        font-style: $style;
        src: url('resources/fonts/#{$file}.ttf') format('truetype');
    }
}

@include fontface(defaultFont, 'VistaSans/VistaSanAltBoo', normal);
@include fontface(titleFont, 'VistaSans/VistaSansBlack', bolder);
